@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Inter:wght@400;500;600;700;800;900&display=fallback');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Additional styles */
@import 'additional-styles/utility-patterns.css';
@import 'additional-styles/range-slider.css';
@import 'additional-styles/toggle-switch.css';
@import 'additional-styles/theme.css';

/* Additional Tailwind directives: https://tailwindcss.com/docs/functions-and-directives/#responsive */
@layer utilities {
    .rtl {
        direction: rtl;
    }
}

/* See Alpine.js: https://github.com/alpinejs/alpine#x-cloak */
[x-cloak=""] {
    display: none;
}


.tradingview-widget-copyright {
    display: none;
}